* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default !important;
  user-select: none;

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: lighten(black, 30%);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: lighten(black, 80%);
  }
}

html {
  font-size: 62.5%;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.1rem;
}

ul {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

html,
#root,
.viewport {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

@media screen and (max-width: 639px) {
  .viewport {
    background: url("../images/wallpaper.jpg") no-repeat center center;
    background-size: cover;
  }
}

@media screen and (min-width: 640px) and (max-width: 1920px) {
  .viewport {
    background: url("../images/wallpaper_hd.jpg") no-repeat center center;
    background-size: cover;
  }
}

@media screen and (min-width: 1921px) {
  .viewport {
    background: url("../images/wallpaper_uhd.jpg") no-repeat center center;
    background-size: cover;
  }
}

.ant-popover {
  z-index: 1;
}

.ant-modal {
  max-width: unset;
  width: 70rem;

  &-header {
    padding: 1.6rem 2.4rem;
  }

  &-body {
    padding: 2.4rem;
    font-size: 1.4rem;
  }
}

.ant-modal-wrap {
  pointer-events: none;
  overflow: hidden !important;
}

.ant-btn {
  height: 3.2rem;
  padding: 0.4rem 1.5rem;
  font-size: 1.4rem;
}

.ant-input-affix-wrapper,
.ant-input {
  padding: 0.4rem 1.1rem;
  font-size: 1.4rem;
  border: 0.1rem solid #434343;
}

.ant-input-group-addon {
  padding: 0 1.1rem;
  font-size: 1.4rem;
}

.ant-comment {
  font-size: 1.4rem;

  &-inner {
    padding: 1.6rem 0;
  }

  &-avatar {
    margin-right: 1.2rem;
  }

  &-content {
    font-size: 1.4rem;

    &-author {
      font-size: 1.4rem;
      margin-bottom: 0.4rem;

      & > span {
        padding-right: 0.8rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
}

.ant-avatar {
  font-size: 1.4rem;
  height: 3.2rem;
  width: 3.2rem;
  line-height: 3.2rem;
}

.author {
  position: absolute;
  bottom: 7.5rem;
  right: 2.5rem;
  font-family: "Beau Rivage", cursive;
  font-size: 4rem;
}

.ant-menu-item {
  display: flex;
  align-items: center;
  height: 4rem !important;
  line-height: 4rem !important;

  .anticon {
    font-size: 1.4rem;
    min-width: 1.4rem;
  }

  .ant-menu-title-content {
    margin-left: 1rem;
    font-size: 1.4rem;
  }
}

.ant-card {
  font-size: 1.4rem;
  border-radius: 0.2rem;

  &-body {
    padding: 2.4rem;
  }

  &-meta {
    margin: -0.4rem 0;

    &-detail {
      > div:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    &-title {
      font-size: 1.6rem;
    }
  }
}
