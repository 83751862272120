.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;

  .taskbar {
    display: flex;

    &-search {
      display: flex;
      align-items: center;
      margin: 0 1rem;
      font-size: 1.4rem;

      .ant-input-group-wrapper {
        height: 3.2rem;

        .ant-input-group-addon {
          height: 3.2rem;
          border-right: 0.1rem solid #434343;
          font-size: 1.4rem;
          padding: 0 1.1rem;
        }

        .ant-input {
          height: 3.2rem;
          width: 0;
          padding: 0;
          opacity: 0;
          transition: all 0.25s ease-in-out;
          font-size: 1.4rem;
        }
      }
    }

    &-search--collapsed {
      .ant-input-group-wrapper {
        .ant-input-group-addon {
          border-right: none;
        }

        .ant-input {
          width: 100%;
          padding: 0.4rem 1.1rem;
          opacity: 1;
        }
      }
    }
  }

  &-datetime {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    font-size: 1.3rem;

    &:hover {
      background-color: lighten($black, 10%);
    }

    &__xl {
      display: flex;
      flex-direction: column;

      .date {
        color: $lightblue;
        font-weight: lighter;
        font-size: 1.4rem;
      }

      .clock {
        font-size: 4.5rem;
        font-weight: lighter;
      }
    }
  }

  &-calendar {
    background: transparent;
    font-size: 1.4rem;

    .ant-picker-panel {
      background: transparent;
    }

    .ant-picker-content {
      height: 25.6rem;

      th {
        width: 3.6rem;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__actions {
        display: flex;
        align-items: center;

        .icon {
          font-size: 2rem;
          margin-right: 1.5rem;
        }

        .icon:first-child {
          margin-right: 2.5rem;
        }
      }
    }
  }

  &-icon {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;

    svg {
      height: 2rem;
      width: 2rem;
    }

    &:hover {
      background-color: lighten($black, 7%);
      color: $blue;
    }
  }

  .app-icon {
    &:hover {
      color: inherit;
    }

    &-running {
      border-bottom: 0.15rem solid $blue;

      &:hover {
        background-color: lighten($black, 15%);
      }
    }

    &-active {
      background-color: lighten($black, 10%);

      &:hover {
        background-color: lighten($black, 15%);
      }
    }
  }
}

.search-result-wrapper {
  width: 80rem;
  height: 60rem;

  .search-result {
    display: flex;
    height: fit-content;

    .search-info {
      flex: 1;

      &-item {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        width: 35rem;

        &:hover {
          background: lighten($black, 15%);
        }
      }
    }
  }
}
