.icon {
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 50%;

  &:hover {
    background: lighten($black, 25%);
  }
}

.custom-icon {
  height: 3.2rem;
  width: 3.2rem;
}

.anticon,
.custom-icon {
  pointer-events: none;
}
