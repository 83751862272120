.notepad {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  * {
    font-family: "Segoe UI", sans-serif !important;
  }

  &-editor {
    flex: 1;
    width: 81.6rem;
    resize: none;
    font-size: 1.8rem;
    padding: 2.5rem;
    background: $white;
    margin: 0 auto;
    color: $black;
  }

  &-toolbar {
    background: lighten($black, 15%) !important;
    border: none !important;
    color: $black !important;
    position: sticky;
    z-index: 2;

    .rdw-embedded-modal {
      width: 250px;
    }

    .rdw-colorpicker-modal {
      width: 230px !important;
    }

    .rdw-link-modal {
      height: 230px;
    }

    .rdw-colorpicker-modal-options {
      overflow-x: hidden;
      width: 200px !important;
    }

    .rdw-option-wrapper {
      border: none !important;
    }

    .rdw-option-active {
      background: $lightblue;
    }

    .rdw-dropdown-wrapper {
      height: 20px !important;
      min-width: 88px !important;

      span {
        font-size: 14px !important;
      }
    }

    .rdw-dropdown-optionwrapper {
      width: 102% !important;
      min-width: 86px !important;
      left: -1px !important;
      overflow-x: hidden !important;

      .rdw-dropdownoption-default {
        color: $black;
        width: 101% !important;
        padding: 5px 15px !important;
        overflow-x: hidden !important;
      }
    }
  }
}
