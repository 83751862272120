.calculator {
  display: flex;
  width: 100%;

  &-output {
    font-size: 1.8rem;
    color: darken($white, 35%);
    font-weight: bold;
    text-align: end;
    width: 100%;
    margin-bottom: 0;
    min-height: 3rem;
  }

  &-result {
    font-size: 5rem;
    color: $white;
    text-align: end;
    width: 100%;
  }

  &-left {
    padding: 0.5rem;
  }

  &-right {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  &-history-title {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: normal;
    color: $white;
  }

  &-history-items {
    width: 100%;
    height: 49rem;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: end;
    font-size: 2rem;
    font-weight: bold;
    color: darken($white, 35%);
    padding-right: 1.5rem;
  }

  &-buttons {
    display: grid;
    grid-template-columns: repeat(4, 7.5rem);
    gap: 0.1rem;
    grid-auto-rows: 7.5rem;

    button {
      border: none;
      background: lighten($black, 15%);
      font-size: 2rem;

      &:hover {
        background: lighten($black, 10%);
      }
    }

    button[data-variant="number"] {
      background: lighten($black, 25%);

      &:hover {
        background: lighten($black, 20%);
      }
    }

    button[data-value="="] {
      background: $blue;

      &:hover {
        background: darken($blue, 5%);
      }
    }
  }
}
