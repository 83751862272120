.desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;

  &-shortcuts {
    height: calc(100% - 5rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-shortcut {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.2rem 1.5rem;
    margin: 1.5rem;
    width: 8.5rem;
    border: 0.1rem solid transparent;
    z-index: 1;

    &:hover {
      background: rgba(0, 0, 0, 0.2) !important;
    }

    .shortcut-label {
      text-shadow: -0.1rem -0.1rem 0 #000, 0.1rem -0.1rem 0 #000,
      -0.1rem 0.1rem 0 #000, 1px 1px 0 #000;
      font-size: 1.6rem;
      white-space: nowrap;
    }
  }

  &-icon {
    font-size: 4.8rem;
    line-height: 1.1;

    svg {
      height: 4.8rem;
      width: 4.8rem;
    }
  }
}

.fullscreen-trigger {
  position: absolute;
  font-size: 3rem;
  top: 1.5rem;
  right: 2.5rem;

  &:hover {
    color: $blue;
  }
}
