.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin box-shadow() {
  box-shadow: 0 0.3rem 0.6rem -0.4rem rgba(0, 0, 0, 0.48),
  0 0.6rem 1.6rem 0 rgba(0, 0, 0, 0.32),
  0 0.9rem 2.8rem 0.8rem rgba(0, 0, 0, 0.2);
}
