.appLoading {
  height: 100vh;
  width: 100vw;
  background: $black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-wrapper {
  width: 60%;
}
