.videos {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;

  &-buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    & > div {
      margin: 1rem;
    }
  }

  .streams {
    position: relative;
    width: 75%;
    max-width: 80rem;
    min-height: 43rem;

    .owner,
    .callee {
      padding: 0.1rem 1.5rem;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.5rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .stream-owner {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      video {
        height: 15rem;
      }
    }

    .stream-callee {
      position: relative;

      video {
        width: 100%;
      }
    }
  }

  .chat {
    height: 30rem;
    width: 75%;
    display: flex;
    flex-direction: column;

    &-iframe {
      width: 19.5rem;
    }

    .messages {
      flex: 1;
      overflow-y: auto;

      p {
        background: rgba(0, 0, 0, 0.25);
        border-radius: 0.5rem;
        padding: 1rem;
      }
    }
  }
}

.video-call-menu {
  flex: 1;
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    color: red;
    text-align: center;
  }

  .divider {
    margin: 2rem 0;
  }

  &-name {
    margin-bottom: 1rem;
  }

  .video-call-join {
    display: flex;
    align-items: center;
  }
}
