@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&display=swap');

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("../font/Segoe UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic"),
  url("../font/Segoe UI Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"), url("../font/Segoe UI Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold Italic"),
  url("../font/Segoe UI Bold Italic.woff") format("woff");
}

* {
  font-family: "Segoe UI", sans-serif;
}
