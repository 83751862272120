.start-menu {
  height: 60rem;
  width: 35rem;
  background-color: lighten($black, 7%);
  display: flex;
  flex-direction: column-reverse;

  .ant-dropdown-menu {
    background: none;
    border-right: none;
    box-shadow: none;
  }
}

.start-menu-container {
  top: calc(100vh - 65rem) !important;
  @include box-shadow;
}
