.stories {
  &-home {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    height: 60rem;
    overflow-y: scroll;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 2rem;
    }
  }

  &-breadcrumb {
    text-transform: capitalize;
  }

  .story {
    height: 60rem;
    overflow-y: scroll;
    padding: 0 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    &-list {
      &-wrapper {
        width: 100%;
      }

      &-item {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        .story-text {
          margin: 0;
          padding: 0;
        }

        .anticon {
          margin-top: 0.5rem;

          svg {
            path {
              fill: $blue;
            }
          }
        }
      }
    }

    &-quote {
      background: lighten($black, 20%);
      padding: 2rem;
      border-radius: 0.5rem;

      .story-text {
        padding: 0 5rem;
      }

      .quote-icon {
        svg {
          path {
            fill: $lightblue;
          }
        }

        &:last-of-type {
          margin-left: calc(100% - 3.5rem);
        }
      }
    }

    &-title {
      text-align: center;
      font-size: 5rem;
      font-weight: bold;
      margin-bottom: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      small {
        font-size: 2rem;
        font-weight: normal;
        color: darken($white, 30%);
      }
    }

    &-text {
      text-align: justify;
      width: 100%;

      &_underline {
        text-decoration: underline;
        text-underline-offset: 0.25rem;
      }
    }

    &-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5rem 0;
      font-size: 1.8rem;

      small {
        color: darken($white, 30%);
      }
    }

    &-image {
      width: 30rem;
      height: 30rem;
      border-radius: 0.5rem;
    }
  }
}
