.ttt {
  height: 80rem;
  width: 100%;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: relative;

  .timer {
    position: absolute;
    top: -22rem;
  }

  .music-toggle {
    position: absolute;
    top: 3rem;
    right: 3rem;
    height: 5rem;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    border-radius: 50%;

    &:hover {
      background: lighten($black, 25%);
    }
  }

  .switch {
    transform: scale(2);

    .ant-switch-inner {
      font-size: 1.4rem;
    }
  }

  .status-counter {
    font-size: 2rem;
    margin: 0;
    padding: 0;

    label {
      display: inline-block;
      width: 7rem;
    }
  }

  .piece-chooser-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;

    .piece-chooser {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 5rem;

      .ttt-btn-wrapper {
        margin: 0 2.5rem;
      }
    }
  }

  &-text {
    font-size: 3rem;
    text-align: center;
    margin-top: 1.5rem;
  }

  &-board {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2.5rem;
    width: 32.5rem;
  }

  &-btn-wrapper {
    height: 9rem;
    width: 9rem;
    border: none;
    border-radius: 1.5rem;
    background: linear-gradient(
        315deg,
        lighten(#1f1f1f, 10%) 0%,
        darken(#1f1f1f, 10%) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -0.5rem -0.5rem 3rem 0 rgba(0, 0, 0, 0.75);
    transition: all 0.25s ease-in-out;

    &:hover {
      transform: scale(1.05);
      box-shadow: -0.5rem -0.5rem 4rem 0 rgba(0, 0, 0, 0.75);
    }

    &:active {
      transform: scale(0.95);
      box-shadow: -0.5rem -0.5rem 1.5rem 0 rgba(0, 0, 0, 0.75);
    }
  }

  &-btn {
    height: 7.5rem;
    width: 7.5rem;
    border: none;
    border-radius: 1.5rem;
    background: linear-gradient(
        315deg,
        darken(#1f1f1f, 10%) 0%,
        lighten(#1f1f1f, 10%) 100%
    );
    font-size: 4rem;
    font-weight: bold;
    text-shadow: 0.2rem -0.2rem 1rem lighten($black, 10%);
  }
}

.winning-line-wrap {
  position: absolute;
  box-sizing: border-box;
  padding: 0 0.5rem;
  z-index: 9;
  width: 100%;
  display: none;

  &[class*="winning-line-wrap-"] {
    display: block;

    .winning-line {
      animation-name: line;
    }
  }

  .winning-line {
    height: 0.5rem;
    margin-top: -0.7rem;
    background: #fd6593;
    transition: all 0.2s ease-in-out;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
}

.winning-line-wrap-0-2 {
  top: 16.6667%;
}

.winning-line-wrap-3-5 {
  top: 52%;
}

.winning-line-wrap-6-8 {
  top: 87.3333%;
}

.winning-line-wrap-0-6,
.winning-line-wrap-1-7,
.winning-line-wrap-2-8 {
  transform: rotate(90deg);
  transform-origin: top left;
}

.winning-line-wrap-0-6 {
  left: 12.6667%;
}

.winning-line-wrap-1-7 {
  left: 48.33%;
}

.winning-line-wrap-2-8 {
  left: 84.3333%;
}

.winning-line-wrap-0-8,
.winning-line-wrap-2-6 {
  width: 137.4214%;
  padding: 0 1rem;
  transform-origin: top left;
  margin-top: 0.7rem;
}

.winning-line-wrap-0-8 {
  transform: rotate(44.7deg);
}

.winning-line-wrap-2-6 {
  bottom: -0.4rem;
  transform: rotate(-44.6deg);
  margin-left: 0.5rem;
  margin-bottom: 0.4rem;
}
