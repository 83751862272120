.widget {
  position: absolute;
  top: 15rem;
  right: 2.5rem;
  width: 45rem;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-nav-list {
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0;

    .anticon {
      margin-right: 0;
    }
  }

  &-wrapper {
    min-height: 60rem;
    max-height: 60rem;
    overflow-y: auto;
    background: rgba($black, 0.4);
    border-radius: 0.5rem;
  }

  &-icon {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 3.5rem;
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
  }

  &-aboutMe {
    padding: 2.5rem;
    font-size: 1.6rem;

    &-cta {
      margin: 0 auto 1.5rem auto;
      display: block;
      height: 3.2rem;
      font-size: 1.4rem;
      padding: 0.4rem 1.5rem;
    }

    ul {
      padding: 0 2.5rem;
    }

    h2 {
      text-transform: uppercase;
      text-align: center;
    }

    p {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
    }
  }

  &-skills {
    padding: 2.5rem;
    font-size: 1.6rem;

    .skills-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 2rem;
      font-weight: bold;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  &-contact {
    padding: 2.5rem;
    font-size: 1.6rem;

    p {
      margin-bottom: 5rem;
      font-weight: bold;
      font-size: 1.8rem;
    }

    .contact-item {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      span {
        margin-left: 1.5rem;
      }

      .custom-icon {
        height: 3rem;
        width: 3rem;

        path {
          fill: $white;
        }
      }
    }

    .contact-socialMedia {
      margin-top: 5rem;
      display: flex;
      justify-content: center;

      & > * {
        margin: 0 1.5rem;
      }
    }
  }

  &-experience {
    padding: 2.5rem;
    font-size: 1.6rem;
    margin: 2rem 0 0 1.5rem;

    .icon {
      svg {
        border-radius: 0.5rem;
      }
    }

    .icon:hover {
      background: none;
    }

    .ant-timeline-item-head-custom {
      background-color: transparent;
    }

    .ant-timeline-item-content {
      margin-left: 3.5rem;
    }

    h3 {
      font-size: 1.6rem;
    }

    span {
      font-size: 1.6rem;
      margin-right: 1.5rem;
    }

    small {
      font-size: 1.4rem;
      color: darken($white, 15%);
    }
  }
}
