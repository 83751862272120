.app-info {
  flex: 2;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-icon {
    font-size: 10rem;

    svg {
      height: 10rem;
      width: 10rem;
    }
  }

  h1 {
    color: $lightblue;
    margin: 0;
  }

  h3 {
    margin: 1.5rem 5rem;
    text-align: center;
  }

  small {
    font-size: 1.4rem;
    color: lighten($black, 50%);
  }

  ul {
    margin: 0 5rem;
  }

  li {
    color: lighten($black, 50%);
  }
}
