.dialog-action {
  font-size: 1.5rem;
  padding: 0.5rem 0.7rem;
  margin-left: 0.5rem;
  color: lighten($black, 50%);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: lighten($black, 20%);
    color: $white;
  }
}

.dialog-title {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;

  &-wrapper {
    display: inline-flex;
    align-items: center;
  }

  &-input {
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    margin-left: 0.5rem;
  }
}

.dialog-icon {
  margin-right: 0.5rem;

  svg {
    height: 2rem;
    width: 2rem;
  }
}

.dialog-regular {
  .ant-modal-content {
    max-height: calc(100vh - 20rem) !important;
  }

  .ant-modal-body {
    max-height: calc(100vh - 30rem) !important;
    min-height: 50rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
}

.maximized {
  width: 100vw !important;
  height: calc(100vh - 5rem) !important;
  top: 0 !important;
  left: 0 !important;

  .react-draggable {
    transform: translate(0, 0) !important;
  }

  .ant-modal-content {
    width: 100vw !important;
    height: calc(100vh - 5rem) !important;
    top: 0 !important;
    left: 0 !important;
  }

  .ant-modal-body {
    width: 100vw !important;
    height: calc(100vh - 10.5rem) !important;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0 !important;
    display: flex;
    flex-direction: column;
  }
}
