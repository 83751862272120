.screen-warning {
  height: 100vh;
  width: 100vw;
  padding: 2rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  button {
    height: 3.2rem;
    padding: 0.4rem 1.5rem;
    font-size: 1.4rem;
  }

  code {
    color: $blue;
  }

  h3 {
    font-size: 2.6rem;
  }

  small {
    margin-bottom: 2rem;
    font-size: 2.2rem;
  }

  &-icon {
    font-size: 5rem;
    margin-bottom: 2rem;
    color: orange;

    &--success {
      font-size: 5rem;
      margin-bottom: 2rem;
      color: green;
    }
  }

  .resolution-scanner {
    height: 225px;
    width: 300px;
    background: #434343;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .supported-size {
      height: 130px;
      width: 200px;
      border: 5px green dashed;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      z-index: 5;
    }

    .unsupported-size {
      border: 5px red dashed;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}
